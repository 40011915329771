import React, { Component } from "react";
import PropTypes from 'prop-types';
import { formatDate } from "../../util/CommonUtils";
import { Row, Col, Card, Button, Descriptions, message, Tooltip } from 'antd'
import { color, contentCard } from "../../styles";
import DeviceMap from "../DeviceMap"
import BatteryGauge from "react-battery-gauge";
import ProgressBar from "@ramonak/react-progress-bar";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import DeviceConfigurationModal from "../DeviceConfigurationDialog"
import LicenseAllocationModal from "../LicenseAllocationDialog"
import RecoveryModeModal from "../RecoveryModeDialog"
import { SettingFilled } from '@ant-design/icons';
import { Gear, Book, Trash, FileEarmarkExcel, FileEarmark, CloudUpload, Eye, GearWide } from "react-bootstrap-icons";
import { DisconnectOutlined } from "@ant-design/icons";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from "chart.js";

import {
    FilePdfOutlined
} from '@ant-design/icons';

import { Line } from "react-chartjs-2";
import { primaryButton } from "../../styles";
import API from "../../services/api";
import getPublicURL from "../../utilities/public-url.utility";
import { FederationModal } from "../FederationModal";
import PresetDeviceConfigDialogue from "../PresetDeviceConfigDialogue";
import moment from "moment";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
);

export default class Device extends Component {

    constructor(props) {
        super(props)
        this.expiryPercent = 0;
        this.state = {
            federationModalVisible: false,
            LicenseAllocated: false,
            graphData: [],
            deviceConfigModal: {
                deviceData: null,
                visible: false,
                advanced: false,
            },
            graphColour: 'red',
        }

        // Labels for device record history graph
        this.dateLabelArray = [];
        const date = new Date();
        for (let i = 0; i < 6; i++) {
            this.dateLabelArray.unshift(`${date.getDate()}/${date.getMonth() + 1}`);
            date.setDate(date.getDate() - 1);
        }
    }

    // Prettify the display of relative time stamps
    arrangeTime(seconds) {
        const days = (Math.floor(seconds / (3600 * 24)));
        const dayLabel = days > 0 ? (`${days} day${(days > 1) ? 's' : ''} `) : '';

        seconds -= days * 3600 * 24;
        const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');

        seconds -= hrs * 3600;
        const mins = Math.floor(seconds / 60).toString().padStart(2, '0');

        seconds -= mins * 60;
        seconds = seconds.toString().padStart(2, '0');

        return `${dayLabel}${hrs}:${mins}:${seconds}`;
    }

    calculateVP(voltage) {
        // Ensure voltage parameter is valid
        if (voltage === null) {
            voltage = 0;
            return;
        }
        this.voltagePercent = 0
        if (voltage.includes('%')) {
            voltage = parseFloat(voltage);
            if (voltage > 70) {
                this.voltagePercent = voltage;
                this.setState({ graphColour: 'green' });
            } else if (voltage >= 40) {
                this.voltagePercent = voltage;
                this.setState({ graphColour: 'orange' });
            } else {
                this.voltagePercent = 30;
                this.setState({ graphColour: 'red' });
            }
            return;
        }


        // ohh bewn
        voltage = parseFloat(voltage)

        if (voltage > 3.7) {
            this.voltagePercent = 80;
            this.setState({ graphColour: 'green' })
        } else if (voltage > 3.5) {
            this.voltagePercent = 50;
            this.setState({ graphColour: 'orange' })
        } else {
            this.voltagePercent = 30;
            this.setState({ graphColour: 'red' })
        }
    }

    calculateED(start, end) {
        const now = Date.now() / 1000
        this.expiryPercent = ((now - start) / (end - start)) * 100
    }

    getLocation() {
        API.devicedashboard.getData(
            this.props.expandedContent[0].id,
            this.props.expandedContent[0].lastCommsTimestamp,
            this.props.expandedContent[0].model,
        )
            .then((result) => {
                this.setState({ graphData: result[1] });
                this.gps_time = result[0].device_utc_date_time
                this.cords = {
                    lat: result[0].latitude,
                    lng: result[0].longitude
                }
                this.speed = result[0].speed
                this.heading = result[0].heading
                this.gps_time = result[0].device_utc_date_time

                this.setState({});

                if (this.cords.lat > 90
                    || this.cords.lat < -90
                    || this.cords.lng > 180
                    || this.cords.lng < -180
                ) {
                    message.error('Invalid GPS location');
                }
            })
    }

    getProfile() {
        API.profileCounter.getProfileCounters(this.props.expandedContent[0].id)
            .then((result) => {
                this.profileData = result;
                this.setState({});
            })
    }

    async getFederation() {
        await API.federation.getFederation(this.props.expandedContent[0].id)
            .then((res) => {
                if (res.federation.length > 0) {
                    this.props.setIsFederated(true);
                    this.federation = res.federation[0];
                } else {
                    this.props.setIsFederated(false);
                }
            }).catch((err) => {
                console.log(err)
            })

    }

    federationClick(e) {

        if (e !== undefined) {
            if (this.props.isFederated) {
                // this.props.setIsFederated(false);
                API.federation.remove(this.federation.id)
                    .then((res) => {
                        message.success('Federation was successfully removed');
                        this.props.setIsFederated(false);
                    })
                    .catch((err) => {
                        message.error(`${err.response.data.message}`);
                    })
            } else {
                // this.props.setIsFederated(true);
                API.federation.create({ device_id: String(this.props.expandedContent[0].id) })
                    .then((res) => {
                        if (res.federation.length > 0) {
                            this.federation = res.federation[0]
                        }
                        message.success('Federation was successfully added');
                        this.props.setIsFederated(true);
                    })
                    .catch((err) => {
                        message.error(`${err.response.data.message}`);
                    })
            }
        }
    }

    licenseUpdate(e) {
        this.props.onLicenseAllocation(e);
        this.setState({ LicenseAllocated: true })
    }

    componentDidMount() {
        this.calculateVP(this.props.expandedContent[0].battery_info)
        this.calculateED(this.props.expandedContent[0].created_at, this.props.expandedContent[0].ed)
        this.getLocation();
        if (this.props.expandedContent[0].model === 'REDBACK' || this.props.expandedContent[0].model === 'WhiteTail') {
            this.getProfile()
            this.getFederation()
        }
        if (this.props.expandedContent[0].ed) {
            this.setState({ LicenseAllocated: true });
        }

    }

    doesProfileCount(deviceProxy) {
        if (deviceProxy.model === 'REDBACK' || deviceProxy.model === 'WhiteTail') {
            return true;
        }
        return false;
    }

    DownloadPDF = (name) => {
        API.user.manual(name);
    }

    checkmanual = (name) => {
        const manuals = ['REDBACK', 'MC350']
        if (manuals.includes(name)) {
            return true;
        }
        return false;
    }

    createDisplayVoltage = (record) => {
        let batteryText = "";
        if (record.battery_info) {
            if (typeof(record.battery_info) === 'string' && record.battery_info.includes('%')) {
                    // this will be a percentage
                    batteryText = record.battery_info;
            } else {
                batteryText = `${record.battery_info}V`;
            }
        }
        return batteryText;
    }

    render() {
        if (this.props && (this.props.expandedContent.length > 0) && this.props.expandedContent[0].battery_info && this.props.expandedContent[0].battery_info.includes('&&')) {
            this.props.expandedContent[0].battery_info = this.props.expandedContent[0].battery_info.split("&&");
            this.props.expandedContent[0].battery_info = this.props.expandedContent[0].battery_info[1];
        }
        console.log(this.props.configurationSchema);
        return (
            <div style={{ height: '100%' }}>
                {
                    this.props.expandedContent[0]
                        ?
                        <Row
                            gutter={[16, 0]}
                            style={{
                                background: 'white !important',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            {(this.props.lite === false && this.doesProfileCount(this.props.expandedContent[0]))
                                ?
                                <Col span={7}>
                                    <Card
                                        bordered={false}
                                        style={contentCard()}
                                    >
                                        {/* {(this.doesProfileCount(this.props.expandedContent[0]))
                                            ? */}
                                        <div>
                                            {
                                                (this.profileData)
                                                    ?
                                                    <Descriptions style={{ overflow: "scroll", height: '90%', width: '90%', position: 'absolute' }} title={"Updated At - " + formatDate(this.profileData.profileCounter[0].updatedAt / 1000, 'HH:mm:ss')} bordered>
                                                        <Descriptions.Item label='Accelerometer Wakeups' span={3}>
                                                            {this.profileData.profileCounter[0].accelerometerWakeups}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Battery voltage' span={3}>
                                                            {this.profileData.profileCounter[0].batteryVoltage}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Failed GPS Fix Time' span={3}>
                                                            {this.arrangeTime(this.profileData.profileCounter[0].failedGPSFixTime)}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Failed GPS Fixes' span={3}>
                                                            {this.profileData.profileCounter[0].failedGPSFixes}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Failed Upload Time' span={3}>
                                                            {this.arrangeTime(this.profileData.profileCounter[0].failedUploadTime)}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Failed Uploads' span={3}>
                                                            {this.profileData.profileCounter[0].failedUploads}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Initial Battery Voltage' span={3}>
                                                            {this.profileData.profileCounter[0].initialBatteryVoltage}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Max Temprature' span={3}>
                                                            {this.profileData.profileCounter[0].maximumTemperature}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Successful GPS Fix Time' span={3}>
                                                            {this.arrangeTime(this.profileData.profileCounter[0].successfulGPSFixTime)}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Successful GPS Fixes' span={3}>
                                                            {this.profileData.profileCounter[0].successfulGPSFixes}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Successful Upload Time' span={3}>
                                                            {this.arrangeTime(this.profileData.profileCounter[0].successfulUploadTime)}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Successful Uploads' span={3}>
                                                            {this.profileData.profileCounter[0].successfulUploads}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Trips' span={3}>
                                                            {this.profileData.profileCounter[0].trips}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='Uptime' span={3}>
                                                            {this.arrangeTime(this.profileData.profileCounter[0].upTime)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    :
                                                    <h3>No Profile Counter Available for Device</h3>
                                            }
                                        </div>
                                        {/* : */}
                                        {/* // <div style={{ width: "100%" }}><img style={{ width: "100%" }} src={`${getPublicURL()}/shareing-feature-placeholder.png`}></img></div> */}
                                        {/* <div><p>feature coming soon</p></div> */}
                                        {/* []
                                        } */}
                                    </Card>
                                </Col>
                                :
                                []
                            }
                            <Col span={(this.props.lite) ? 16 : 7}>
                                <Row gutter={[16, 16]} style={{ height: '100%' }}>
                                    <Col span={12}>
                                        <Card
                                            bordered={false}
                                            style={contentCard()}
                                        >
                                            <h4>Battery</h4>
                                            {this.createDisplayVoltage(this.props.expandedContent[0])}
                                            <BatteryGauge
                                                value={this.voltagePercent}
                                                size={"100%"}
                                                customization={{
                                                    batteryMeter: {
                                                        lowBatteryValue: 10,
                                                        fill: this.state.graphColour
                                                    },
                                                    readingText: {
                                                        showPercentage: false,
                                                        fontSize: "0px",
                                                    }
                                                }}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card
                                            bordered={false}
                                            style={contentCard()}
                                        >
                                            <h4>License Expiry</h4>
                                            <p>{formatDate(this.props.expandedContent[0].ed, 'HH:mm:ss')}</p>
                                            <br></br>
                                            <p>{`Days Remaining: ${Math.floor((this.props.expandedContent[0].ed - moment().unix()) / 86400)}`}</p>
                                            {

                                                this.expiryPercent < 90.0
                                                    ?
                                                    <ProgressBar
                                                        completed={this.expiryPercent}
                                                        customLabel={" "}
                                                        bgColor='#0066CC'
                                                        baseBgColor="white"
                                                    />
                                                    :
                                                    <ProgressBar
                                                        completed={this.expiryPercent}
                                                        customLabel={" "}
                                                        bgColor='red'
                                                        baseBgColor="white"
                                                    />
                                            }

                                            <br></br>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card
                                            bordered={false}
                                            style={contentCard()}
                                        >
                                            <h4>Recent History</h4>
                                            <p>Location Records per Day</p>
                                            <Line
                                                datasetIdKey='id'
                                                type='line'
                                                data={{
                                                    labels: this.dateLabelArray,
                                                    datasets: [{
                                                        id: 1,
                                                        label: "Location Records",
                                                        data: this.state.graphData,
                                                        borderColor: "#0066CC",
                                                        fill: false,
                                                        backgroundColour: "#0066CC"
                                                    }]
                                                }}

                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: false
                                                        }
                                                    },
                                                    elements: {
                                                        point: {
                                                            borderWidth: 0,
                                                            radius: 6,
                                                            backgroundColor: 'rgba(0,0,0,0)',
                                                        }
                                                    }
                                                }}

                                                style={{ width: '100%' }}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card
                                            bordered={false}
                                            style={contentCard()}
                                        >
                                            <h4>Last Connect</h4>
                                            {this.props.expandedContent[0].lastCommsTimestamp ? (
                                                <div>
                                                    <p>{formatDate(this.props.expandedContent[0].lastCommsTimestamp, 'HH:mm:ss')}</p>
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    Never connected
                                                </React.Fragment>
                                            )}
                                            <br />

                                            <h4>Last GPS Log</h4>
                                            {this.gps_time ? (
                                                <p>{formatDate(this.gps_time, 'HH:mm:ss')}</p>
                                            ) : (
                                                <React.Fragment>
                                                    No GPS Log
                                                </React.Fragment>
                                            )}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            {(this.props.lite === false)
                                ?
                                <Col span={this.doesProfileCount(this.props.expandedContent[0]) ? 7 : 14}>
                                    <Card
                                        bordered={false}
                                        style={contentCard()}
                                        bodyStyle={{ padding: '0', height: '100%' }}
                                    >
                                        {!this.cords &&
                                            <div
                                                style={{
                                                    borderRadius: '8px',
                                                    position: "absolute",
                                                    zIndex: 999,
                                                    height: "100%",
                                                    width: "100%",
                                                    backgroundColor: "#474747",
                                                    opacity: 0.6,
                                                    textAlign: "center",
                                                    paddingTop: "40%"
                                                }}
                                            >
                                                <h5 style={{ color: "white" }}>
                                                    No GPS Data Available
                                                </h5>
                                            </div>
                                        }
                                        <DeviceMap
                                            deviceCords={[this.cords]}
                                            startCamera={this.cords}
                                            speed={[this.speed]}
                                            heading={[this.heading]}
                                            pocket={true}
                                        // selectedTarget={{lat:0,long}}
                                        >

                                        </DeviceMap>
                                    </Card>
                                </Col>
                                :
                                []
                            }
                            <Col span={(this.props.lite) ? 8 : 3}>
                                <Card bordered={false} style={contentCard()} >
                                    <h4>Settings</h4>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            justifyContent: "center",
                                            marginTop: '20%'
                                        }}
                                    >
                                        {/* Preset Configuration Button */}
                                        {!this.props.PresetPerms
                                            && this.props.expandedContent[0].url_prefix !== null
                                            && this.props.expandedContent[0].manufacturer === "ATRAX"
                                            && (
                                                <PresetDeviceConfigDialogue
                                                    title={`Preset Configurations - ${this.props.expandedContent[0].model} ${this.props.expandedContent[0].serial}`}
                                                    device={this.props.expandedContent[0]}
                                                    record={this.props.expandedContent[0]}
                                                    deviceRecoveryPermission={this.props.deviceRecoveryPermission}
                                                >
                                                    <Tooltip title="Preset Configurations">
                                                        <Button style={primaryButton({
                                                            width: '100%',
                                                            marginTop: "10%",
                                                            overflow: 'hidden',
                                                        })}
                                                        >
                                                            <GearWide
                                                                color="white"
                                                                size={16}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </PresetDeviceConfigDialogue>
                                            )}

                                        {/* Advanced Configuration Button */}
                                        {!this.props.AdvancedPerms
                                            && this.props.configurationSchema !== null
                                            && (
                                                <Tooltip title="Configuration">
                                                    <Button
                                                        style={primaryButton({ width: '100%', marginTop: "10%" })}
                                                        onClick={() => {
                                                            const data = {
                                                                deviceData: this.props.expandedContent[0],
                                                                visible: true,
                                                                advanced: true,
                                                            };

                                                            this.setState({ deviceConfigModal: data });
                                                        }}>
                                                        <Gear
                                                            color="white"
                                                            size={16}
                                                        />
                                                        {/* <span style={{
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whitespace: 'nowrap',
                                                        }}>Configuration</span> */}
                                                    </Button>
                                                </Tooltip>
                                            )}

                                        {/* Recovery Mode Button */}
                                        {/*{this.props.deviceRecoveryPermission*/}
                                        {/*    && this.props.expandedContent[0].manufacturer === "ATRAX"*/}
                                        {/*    && (*/}
                                        {/*        <div>*/}
                                        {/*            <Button*/}
                                        {/*                type="primary"*/}
                                        {/*                onClick={()=>{*/}
                                        {/*                    message.info("Flick Drop Button was clicked")*/}

                                        {/*                }}*/}
                                        {/*                style={{}}*/}

                                        {/*            >Flick Drop Button</Button>*/}
                                        {/*        </div>*/}
                                        {/*    )}*/}
                                        {this.props.deviceRecoveryPermission
                                            && this.props.expandedContent[0].manufacturer === "ATRAX"
                                            && (
                                                <div>
                                                    <RecoveryModeModal
                                                        title={`Device Live Mode - ${this.props.expandedContent[0].model} ${this.props.expandedContent[0].serial}`}
                                                        device={this.props.expandedContent[0]}
                                                    >
                                                        <Tooltip title="Live Mode">
                                                            <Button style={primaryButton({
                                                                width: '100%',
                                                                marginTop: "10%"
                                                            })}
                                                            >
                                                                <Eye
                                                                    color="white"
                                                                    size={16}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </RecoveryModeModal>
                                                </div>
                                            )}

                                        {/* Delete Device Button */}
                                        {!this.props.AdvancedConfig
                                            && this.state.LicenseAllocated
                                            && this.props.expandedContent[0]
                                            && (
                                                <DeleteConfirmationModal
                                                    title={`Are you sure you want to deallocate this license?`}
                                                    content={
                                                        ""
                                                    }
                                                    licenseDeallocationModal={true}
                                                    onClickCancel={() => { }}
                                                    onClickOkay={() => {
                                                        let allLicenses = [];

                                                        API.license.getAllLicenses()
                                                            .then(res => {
                                                                allLicenses = res;
                                                            })
                                                            .then(() => {

                                                                const licenseRecord = allLicenses.filter(
                                                                    (x) => x.device_id === this.props.expandedContent[0].id
                                                                );

                                                                if (licenseRecord && licenseRecord.length > 0) {
                                                                    const licenseId = licenseRecord[0].license_id;

                                                                    const obj = {
                                                                        license_id: licenseId,
                                                                        device_id: "",
                                                                    };
                                                                    API.license.allocateLicenseToDevice(obj);
                                                                    message.success("License was deallocated successfully !");
                                                                    this.setState({ LicenseAllocated: false });
                                                                    return this.props.onLicenseDeallocation();
                                                                }
                                                            })
                                                    }}
                                                >
                                                    <Tooltip title="Deallocate License">
                                                        <Button style={primaryButton({
                                                            width: '100%',
                                                            marginTop: "10%"
                                                        })}
                                                        >
                                                            <FileEarmarkExcel />
                                                        </Button>
                                                    </Tooltip>
                                                </DeleteConfirmationModal>
                                            )}
                                        {/* License Allocation Button */}
                                        {!this.state.LicenseAllocated
                                            && !this.props.AdvancedPerms
                                            && (
                                                <div>
                                                    <LicenseAllocationModal
                                                        title={`License Allocation for  - ${this.props.expandedContent[0].model} ${this.props.expandedContent[0].serial}`}
                                                        device={this.props.expandedContent[0]}
                                                        unallocatedLicensesData={this.props.unallocatedLicenses}
                                                        onLicenseAllocatedClicked={(e) => this.licenseUpdate(e)}
                                                        updateData={() => this.props.updateData()}
                                                    >
                                                        <Tooltip title="Allocate License">
                                                            <Button style={primaryButton({
                                                                width: '100%',
                                                                marginTop: "10%"
                                                            })}
                                                            >
                                                                <FileEarmark />
                                                            </Button>
                                                        </Tooltip>
                                                    </LicenseAllocationModal>
                                                    <br />
                                                </div>
                                            )}
                                        {!this.props.AdvancedConfig
                                            && this.props.expandedContent[0]
                                            && (
                                                <DeleteConfirmationModal
                                                    title={`Are you sure you want to detach the device from the current Target and Investigation?`}
                                                    content={
                                                        ""
                                                    }
                                                    licenseDeallocationModal={false}
                                                    onClickCancel={() => { }}
                                                    onClickOkay={() => {
                                                        API.target.detach(this.props.expandedContent[0].id).then((res) => {
                                                            console.log(res);
                                                            if (res.code === 'SUCCESS') {
                                                                message.success("Device was detached successfully !");
                                                            }
                                                        })
                                                            .catch((e) => {
                                                                message.error(e.response.data.message);
                                                            });
                                                        console.log(this.props.expandedContent[0].id);
                                                    }}
                                                >
                                                    <Tooltip title="Detach from Target">
                                                        <Button style={primaryButton({
                                                            width: '100%',
                                                            marginTop: "10%"
                                                        })}
                                                        >
                                                            <DisconnectOutlined />
                                                        </Button>
                                                    </Tooltip>
                                                </DeleteConfirmationModal>
                                            )}
                                        {/* download the manual button */}
                                        {!this.props.PresetPerms &&
                                            this.checkmanual(this.props.expandedContent[0].model) &&
                                            (
                                                <Tooltip title="Download the manual for this device">
                                                    <Button style={primaryButton({
                                                        width: '100%',
                                                        marginTop: "10%",
                                                        overflow: 'hidden',
                                                    })}
                                                        onClick={() => {
                                                            console.log(this.props.expandedContent[0].model)
                                                            this.DownloadPDF(this.props.expandedContent[0].model)
                                                        }}
                                                    >
                                                        <Book
                                                            color="white"
                                                            size="16"
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            )}

                                        {/* Federation Button */}
                                        {this.props.expandedContent[0]
                                            && !this.props.AdvancedConfig
                                            && !this.props.AdvancedPerms
                                            && (
                                                <div>
                                                    <FederationModal
                                                        isVisible={this.state.federationModalVisible}
                                                        cancel={() => {
                                                            this.setState({ federationModalVisible: false })
                                                        }}
                                                        device_id={this.props.expandedContent[0].id}
                                                    />
                                                    <Tooltip title="Federation">
                                                        <Button
                                                            style={
                                                                primaryButton({
                                                                    width: '100%',
                                                                    marginTop: "10%"
                                                                })}
                                                            onClick={() => this.setState({ federationModalVisible: !this.state.federationModalVisible })}
                                                        >
                                                            <CloudUpload
                                                                color="white"
                                                                size={16}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            )}


                                        {/* Delete Device Confirmation Prompt */}
                                        {!this.props.AdvancedPerms
                                            && (
                                                <DeleteConfirmationModal
                                                    title={`Are you sure you want to delete '${this.props.expandedContent[0].serial}'?`}
                                                    content={
                                                        "Deletion of device will permanently delete all tracking data associated with this device and not be recoverable."
                                                    }
                                                    onClickCancel={() => { }}
                                                    onClickOkay={() => {
                                                        this.props.onDelete(this.props.expandedContent[0]);
                                                    }}
                                                >
                                                    <Tooltip title="Delete">
                                                        <Button style={primaryButton({
                                                            width: '100%',
                                                            marginTop: "10%",
                                                            backgroundColor: 'red',
                                                            borderColor: 'red',
                                                        })}
                                                        >
                                                            <Trash
                                                                color="white"
                                                                size={16}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </DeleteConfirmationModal>
                                            )}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        :
                        []
                }
                <DeviceConfigurationModal
                    device={this.state.deviceConfigModal.deviceData}
                    visible={this.state.deviceConfigModal.visible}
                    onCancel={() => { this.setState({ deviceConfigModal: { visible: false, deviceData: null } }) }}
                    schema={this.props.configurationSchema}
                    advanced={this.state.deviceConfigModal.advanced}
                />
            </div >
        )
    }
}

Device.propTypes = {
    expandedContent: PropTypes.array,
    isFederated: PropTypes.bool,
    lite: PropTypes.bool,
    setIsFederated: PropTypes.func,
    onLicenseAllocation: PropTypes.func,
    onLicenseDeallocation: PropTypes.func,
    onDelete: PropTypes.func,
    updateData: PropTypes.func,
    unallocatedLicenses: PropTypes.object,
    configurationSchema: PropTypes.object,
    deviceRecoveryPermission: PropTypes.bool,
    PresetPerms: PropTypes.bool,
    BasicPerms: PropTypes.bool,
    AdvancedPerms: PropTypes.bool,
    AdvancedConfig: PropTypes.bool,
};
